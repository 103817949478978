import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Marquee from "react-fast-marquee";

const TrustedBy = ({ heading, logos }) => {
	return (
		<section className="py-5">
			<Container>
				<Row className="align-items-lg-center">
					<Col
						xxl={2}
						lg={3}
						className="mb-4 mb-lg-0  text-center  text-md-start"
					>
						<h3 className="my-auto text-light-black pb-2">{heading}</h3>
					</Col>
					{logos && logos.length > 0 && (
						<Col xxl={10} lg={9}>
							<Marquee>
								{logos.map((item, i) => (
									<GatsbyImage
										image={
											item.featureImage?.featureImage?.node?.localFile
												.childImageSharp.gatsbyImageData
										}
										style={{ maxWidth: "132px" }}
										alt={item.featureImage?.featureImage?.node?.altText}
										key={i}
										className="me-5"
									/>
								))}
							</Marquee>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default TrustedBy;
