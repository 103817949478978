import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import HeroBannerSmall from "../components/hero-small";
import Layout from "../components/layout";
import TrustedBy from "../components/trusted-by";
import bgImage from "../images/common-bg.png";

const CaseStudiesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "case-studies" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				caseStudiesFields {
					caseStudiesBanner {
						caseStudiesBannerHeading
						caseStudiesBannerContent
						caseStudiesBannerBackgroundImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					caseStudiesSearch {
						caseStudiesSearchHeading
						caseStudiesSearchSubHeading
						caseStudiesSearchContent
						caseStudiesCategories {
							nodes {
								... on WpCategory {
									name
									taxonomyName
									slug
								}
							}
						}
					}
					caseStudiesTrustedBy {
						caseStudiesTrustedByHeading
						selectTrustedByLogos {
							nodes {
								... on WpTrustedByLogo {
									featureImage {
										featureImage {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: AVIF
															quality: 30
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			allWpCaseStudy(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					caseStudyPost {
						pdfOnly
						caseStudyPostBanner {
							caseStudyPdf {
								node {
									mediaItemUrl
								}
							}
						}
					}
					title
					slug
					excerptFields {
						excerpt
					}
					featureImage {
						featureImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					caseStudyTypes {
						nodes {
							name
							slug
						}
					}
				}
			}
			allWpCaseStudyType {
				nodes {
					name
					slug
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, caseStudiesFields },
		allWpCaseStudy,
		allWpCaseStudyType,
	} = data;

	const [allCaseStudies, setAllCaseStudies] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [searchVal, setSearchVal] = useState("");
	const [finalsearchVal, setFinalSearchVal] = useState(null);
	const [numOfItemsToShow, setNumberOfItems] = useState(3);

	const [categoriesWithCaseStudies, setCategoriesWithCaseStudies] = useState(
		[]
	);

	useEffect(() => {
		const caseStudyCountsByCategory = {};

		// Count case studies per category
		allWpCaseStudy.nodes.forEach((study) => {
			study.caseStudyTypes.nodes.forEach((type) => {
				if (caseStudyCountsByCategory[type.slug]) {
					caseStudyCountsByCategory[type.slug]++;
				} else {
					caseStudyCountsByCategory[type.slug] = 1;
				}
			});
		});

		// Filter categories that have case studies
		const filteredCategories = allWpCaseStudyType.nodes.filter(
			(category) => caseStudyCountsByCategory[category.slug]
		);

		setCategoriesWithCaseStudies(filteredCategories);
	}, [allWpCaseStudy, allWpCaseStudyType]);

	useEffect(() => {
		setAllCaseStudies(allWpCaseStudy.nodes);
	}, [allWpCaseStudy]);

	useEffect(() => {
		function filterObjects(array) {
			return array.filter((obj) => {
				const containsKeyword =
					!finalsearchVal ||
					obj.title.toLowerCase().includes(finalsearchVal.toLowerCase());

				const isInCategory =
					!selectedFilter ||
					obj.caseStudyTypes.nodes.some((e) => e.slug === selectedFilter);

				return containsKeyword && isInCategory;
			});
		}

		const filteredData = filterObjects(allWpCaseStudy.nodes);
		setAllCaseStudies(filteredData);
	}, [selectedFilter, finalsearchVal]);

	const handleChangeInput = (val) => {
		setFinalSearchVal(val);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setFinalSearchVal(searchVal);
	};
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Case Studies",
				item: {
					url: `${siteUrl}/case-studies`,
					id: `${siteUrl}/case-studies`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/case-studies`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBannerSmall
					heading={
						caseStudiesFields?.caseStudiesBanner.caseStudiesBannerHeading
					}
					content={
						caseStudiesFields?.caseStudiesBanner.caseStudiesBannerContent
					}
					bgImage={
						caseStudiesFields?.caseStudiesBanner
							.caseStudiesBannerBackgroundImage?.node.localFile.childImageSharp
							.gatsbyImageData
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<section className="py-5 py-md-8">
					<Container>
						<Row className={`align-items-lg-center`}>
							<Col lg={10} xxl={9} className="mb-4 mb-lg-0 text-start pe-lg-6">
								<span className="text-light-black">
									{
										caseStudiesFields?.caseStudiesSearch
											.caseStudiesSearchSubHeading
									}
								</span>
								<h2 className="my-0 pt-2 text-light-black">
									{
										caseStudiesFields?.caseStudiesSearch
											.caseStudiesSearchHeading
									}
								</h2>

								<div
									className="py-4 text-light-black"
									dangerouslySetInnerHTML={{
										__html:
											caseStudiesFields?.caseStudiesSearch
												.caseStudiesSearchContent,
									}}
								></div>
								<div className="d-flex w-100 align-items-center">
									<Form className="w-100" onSubmit={handleSubmit}>
										<Row className="mb-4 text-center text-md-start">
											<Col className="position-relative">
												<Form.Control
													type="text"
													placeholder="Search"
													className="mr-sm-1 border-light-black py-2 rounded text-light-black"
													style={{ height: "52px", paddingLeft: "36px" }}
													onChange={(e) => handleChangeInput(e.target.value)}
													value={finalsearchVal}
												/>
												<CiSearch
													className="position-absolute d-flex align-items-center h-100"
													style={{ top: 0, bottom: 0, left: "24px" }}
												/>
											</Col>
											{/* <Col md="3" className="px-md-0 my-3 my-md-0">
												<Button
													type="submit"
													className="w-100 text-black h-100 montserrat-bold px-2 text-light-black"
													variant="secondary"
													size="lg"
												>
													Search
												</Button>
											</Col> */}
										</Row>
									</Form>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flex flex-wrap align-items-center mt-3">
									<Button
										style={{ borderRadius: "200px" }}
										onClick={() => setSelectedFilter(null)}
										className={`me-2 mb-2 py-2 px-4 cat-buttons ${
											selectedFilter === null
												? "text-primary cat-button-active"
												: "text-category-grey custom-cat-button"
										}`}
										variant={`${
											selectedFilter === null
												? "outline-primary"
												: "outline-category-grey"
										}`}
									>
										View all
									</Button>
									{allWpCaseStudyType &&
										categoriesWithCaseStudies
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((item, index) => (
												<Button
													style={{ borderRadius: "200px" }}
													key={index}
													onClick={() => setSelectedFilter(item.slug)}
													className={`me-2 mb-2 py-2 px-4 cat-buttons ${
														selectedFilter === item.slug
															? "text-primary cat-button-active"
															: "text-category-grey custom-cat-button"
													}`}
													variant={`${
														selectedFilter === item.slug
															? "outline-primary"
															: "outline-category-grey"
													}`}
												>
													{item.name}
												</Button>
											))}
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-md-8" style={sectionWrapper(bgImage)}>
					<Container className="border-top border-white">
						{allCaseStudies.length > 0 && (
							<>
								{allCaseStudies.slice(0, numOfItemsToShow).map((item, i) => {
									const flip = i % 2 !== 0;
									return (
										<Row className="align-items-center border-bottom border-white py-6">
											<Col lg={6} className="pe-md-7 mb-5 mb-lg-0">
												<h2 className="my-0 pt-2 text-white fs-2">
													{item.title}
												</h2>
												<div>
													<p className="py-4 text-white fs-6">
														{item?.excerptFields?.excerpt}
													</p>
												</div>
												{item?.caseStudyPost?.pdfOnly !== true && (
													<Button
														as={Link}
														href={`/case-studies/${item.slug}`}
														size="lg"
														className="p-0 montserrat-semibold text-white fs-6 bg-transparent border-0"
													>
														Read More
														<IoIosArrowForward className="fs-6 ms-2" />
													</Button>
												)}
												{item?.caseStudyPost?.pdfOnly === true && (
													<Button
														href={
															item?.caseStudyPost?.caseStudyPostBanner
																?.caseStudyPdf?.node?.mediaItemUrl
														}
														target="_blank"
														rel="noreferrer"
														size="lg"
														className="p-0 montserrat-semibold text-white fs-6 bg-transparent border-0"
													>
														Download PDF
														<IoIosArrowForward className="fs-6 ms-2" />
													</Button>
												)}
											</Col>
											<Col
												lg={6}
												className={`mb-4 mb-lg-0 text-start pt-4 ${
													flip ? "pe-4" : "ps-4"
												} position-relative`}
											>
												<div
													className="position-relative"
													style={{ zIndex: 2 }}
												>
													<GatsbyImage
														image={
															item.featureImage?.featureImage?.node?.localFile
																.childImageSharp.gatsbyImageData
														}
														alt={item.featureImage?.featureImage?.node?.altText}
														className="rounded-5 border-2 border border-white"
														style={{
															height: "360px",
															width: "100%",
														}}
													/>
												</div>

												<div
													className={`bg-image position-absolute h-50 w-50 top-0 ${
														flip ? "right-0" : "left-0"
													}`}
													style={overlayImageStyle(flip)}
												>
													{flip ? (
														<div
															className="d-none d-md-block"
															style={{
																background: "#E8BC38",
																width: "100%",
																height: "100%",
																borderBottomRightRadius: "30px",
																borderTopLeftRadius: "30px",
															}}
														></div>
													) : (
														<div
															className="d-none d-md-block"
															style={{
																background: "#E8BC38",
																width: "100%",
																height: "100%",
																borderTopRightRadius: "30px",
																borderBottomLeftRadius: "30px",
															}}
														></div>
													)}
												</div>
											</Col>
										</Row>
									);
								})}
								{numOfItemsToShow < allCaseStudies.length && (
									<Button
										size="lg"
										onClick={() => setNumberOfItems(allCaseStudies.length)}
										className="text-white py-3 px-4 montserrat-semibold fs-6 bg-transparent border-white mt-6 mx-auto d-flex"
									>
										View all
									</Button>
								)}
							</>
						)}
						{allCaseStudies.length < 1 && (
							<div>
								<h2 className="my-5 pt-2 text-white text-center fs-2">
									No case study results
								</h2>
							</div>
						)}
					</Container>
				</section>
				<TrustedBy
					heading={
						caseStudiesFields?.caseStudiesTrustedBy?.caseStudiesTrustedByHeading
					}
					logos={
						caseStudiesFields?.caseStudiesTrustedBy?.selectTrustedByLogos?.nodes
					}
				/>
			</Layout>
		</>
	);
};

export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export const overlayImageStyle = (flip) => ({
	zIndex: 1,
	left: flip ? "unset" : 0,
	right: flip ? 0 : "unset",
});

export default CaseStudiesPage;
